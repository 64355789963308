<template>
  <!--  登录以后的-->
  <div class="index">
    <!--      轮播图-->
    <div
      class="slideShow"
      style="height: 335px;
  margin:70px auto 0;"
    >
      <el-carousel :interval="5000" arrow="always" autoplay height="335px">
        <el-carousel-item v-for="item in picList" :key="item.url">
          <div class="Img" :style="`background-image: url(${$downloadUrl+item.url})`" @click="getLoopDetail(item)" />
          <!--            <img :src="$downloadUrl+item.url" alt="" style="width: 100%;height: 360px" >-->
        </el-carousel-item>
      </el-carousel>
      <div v-if="this.$store.state.user.token" class="slide">
        <div class="right">
          <!--                卡片-->
          <el-card class="box-card">
            <div slot="header" class="clearFix">
              <!--                头像-->
              <div class="infoBox" style="overflow: hidden;border-bottom: 1px solid #E7EDF6;">
                <div class="avatar-wrapper" style="width: 32px;height: 32px;background: #4C97FD;border-radius: 32px;margin-bottom: 20px">
                  <img :src="userInfo.url==null?$pic:$downloadUrl + userInfo.url" class="user-avatar">
                </div>
                <div class="name">
                  <p
                    style="  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  max-width: 121px;"
                  > Hi,{{ userInfo.name }}</p>
                  <p>祝你开心每一天</p>
                  <!--                                {{userInfo.sex==0?'先生':'女士'}}-->
                </div>
              </div>
              <div class="message">来天亮教育的
                <span style="color: rgba(67, 146, 253, 1);font-size: 22px;padding-left: 6px">
                  {{ isNaN($moment(Date()).add(1, 'day').diff($moment(userInfo.intoTime), 'days'))?
                    $moment(Date()).add(1, 'day').diff($moment(userInfo.createTime), 'days')
                    :$moment(Date()).add(1, 'day').diff($moment(userInfo.intoTime), 'days') }}
                </span>天
              </div>
              <div class="percent">
                <el-progress :percentage="speedNum" :show-text="false" :stroke-width="10" />
                <div class="msg">学习进度<span>{{ speed }}</span></div>
              </div>
            </div>
            <!--                         <div class="text item">
                                            <div class="box">
                                                {{this.$store.state.dict.dictTree.ea_welcome['remark'][Math.floor((Math.random()*this.$store.state.dict.dictTree.ea_welcome['remark'].length))]}}
                                            </div>
                                        </div>-->
          </el-card>
        </div>
      </div>
    </div>
    <div class="middle">
      <!--公司动态-->
      <div class="publicity">
        <companyDynamics :dynamics-list="DynamicsList" />
      </div>
      <!--课程分类-->
      <div class="publicity">
        <elegantDemeanor :course-list="courseList" />
      </div>
      <!--学员风采-->
      <div class="publicity">
        <StudentDemeanor />
      </div>
    </div>
    <!-- <div class="pic-box">
        <div class="waterfull-box">
          <vue-waterfall-easy :imgsArr="elegantList"
                              :imgWidth="290"
                              :maxCols="4"
                              :gap="20"
                              :reachBottomDistance=0
                              :loadingDotCount='0'
                              class="test">
            <div slot-scope="props"
                 style="padding: 10px;color: #666666;overflow: hidden">
              <div class="item" effect="dark" :content="props.value.info" placement="bottom">
                <div>{{props.value.title}}</div>
              </div>
            </div>
            <div slot="waterfall-over">waterfall-over</div>
          </vue-waterfall-easy>

        </div>

      </div>-->
    <!--      底部-->
    <indexBottom />
    <el-dialog
      :visible.sync="loopShow"
      width="800px"
    >
      <p style="margin: 10px 0px;font-size: 20px">{{ carouselTitle }}</p>
      <div class="content" v-html="detailData" />
      <!--          <div class="create">-->
      <!--            <p style="color: #999093;margin: 15px 0px">创建时间：{{carouselTime}}</p>-->
      <!--            <p style="color: #999093;margin: 15px 0px">创建人：{{carouselUserBy}}</p>-->
      <!--          </div>-->
    </el-dialog>
    <AdvertisingBounced ref="AdvertisingBounced" />
  </div>

</template>

<script>
// import IndexFooter from "@/components/IndexFooter";
import StudentDemeanor from '@/views/index/components/StudentDemeanor'
import indexBottom from '../../components/indexBottom'
// import vueWaterfallEasy from 'vue-waterfall-easy'
import companyDynamics from '../../components/companyDynamics'
import elegantDemeanor from './components/elegantDemeanor'
import { AdChange, getCourseList, getDynamicsList, loginAd } from '../../api/welcome'
import { getMessage, getStuSpeed, getUnRead } from '../../api/login'
import { mapState } from 'vuex'
import AdvertisingBounced from '@/components/activity/AdvertisingDialog'

export default {
  name: 'Index',
  components: {
    indexBottom,
    // vueWaterfallEasy,
    companyDynamics,
    elegantDemeanor,
    StudentDemeanor,
    // IndexFooter,
    AdvertisingBounced
  },
  created() {
    this.getDynamicsList()
    // this.getFaceList(this.initStudentObj)
    this.getSpeed()
    this.getCourseList()
    console.log('111', this.$moment(Date()).add(1, 'day'))
    console.log(this.$moment(this.userInfo.intoTime))
    console.log(this.userInfo)
  },
  /* mounted() {
      window.addEventListener('scroll', this.onScroll);
    },*/
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },

  data() {
    return {
      loopShow: false,
      detailData: '',
      // 轮播图的标题
      carouselTitle: '',
      // 轮播图的创建时间
      carouselTime: '',
      // 轮播图的创建人
      carouselUserBy: '',
      speed: '',
      speedNum: 0,
      picList: [],
      elegantList: [],
      DynamicsList: [],
      courseList: []
      /* initStudentObj:{
          pageSize:20,
          pageNum:1
        }*/
    }
  },
  methods: {
    //
    getLoopDetail(data) {
      // type:（0公司动态，1课程宣传）
      this.$router.push({
        path: '/Dynamicpages',
        query: {
          id: data.id,
          type: 0
        }
      })
    },
    getList() {
      getMessage({ studentId: this.userInfo.id, pageSize: 6, pageNum: 1 }).then(res => {
        if (res.code === 200) {
          this.list = res.rows
          if (this.$route.path === '/home') {
            loginAd({ studentId: this.$store.state.user.userInfo.id }).then(res => {
              if (res.code === 200) {
                if ('data' in res) {
                  this.adDetail = res.data
                  this.dialogVisible = true
                  const url = this.$downloadUrl + res.data.fileUrls
                  this.$refs.dialog.$el.firstChild.style.background = `url(${url}) no-repeat 100% 100% `
                  this.$refs.dialog.$el.firstChild.style.backgroundSize = 'cover'
                  AdChange({ id: this.adDetail.id }).then(res => {
                    console.log(res)
                  })
                }
              }
            })
          }
          getUnRead({ studentId: this.userInfo.id }).then(res => {
            if (res.code === 200) {
              this.total = res.data
            }
          })
        }
      })
    },
    // 获取轮播图 公司动态
    getDynamicsList() {
      getDynamicsList({ pageSize: 10, pageNum: 1, isLoopShow: 0 }).then(res => {
        // console.log(res)
        if (res.code === 200) {
          this.picList = res.rows.map(i => ({
            url: i.loopUrls,
            content: i.content,
            title: i.title,
            createdTime: i.createTime,
            createBy: i.createBy,
            id: i.id
          }))
          this.picList = this.picList.filter((i) => {
            if (i.url !== '') {
              return i
            }
          })
        }
      })
      getDynamicsList({ pageSize: 10, pageNum: 1, isShow: 0 }).then(res => {
        if (res.code === 200) {
          this.DynamicsList = res.rows.filter(i => {
            return i.fileUrls !== ''
          })
        }
      })
    },
    // 课程分类
    getCourseList() {
      getCourseList({}).then(res => {
        if (res.code === 200) {
          this.courseList = res.rows
        }
      })
    },
    // 获取学员风采
    /*  getFaceList(obj){
        faceShowList(obj).then(res=>{
          if (res.code === 200){
            // this.elegantList = []
            console.log(res)
           /!* if(obj.pageNum<=res.total/obj.pageSize+1){
              res.rows.forEach(i=>{
                if (i.isShow===0){
                  this.elegantList.push({src:this.$downloadUrl+i.fileUrls,info:i.content,title:i.title})
                }
              })
            }*!/
          }
        })
      },*/
    // 获取进度
    getSpeed() {
      getStuSpeed({ studentId: this.userInfo.id, classId: this.userInfo.classId }).then(res => {
        this.speed = res.msg
        this.speedNum = Number(res.msg.substr(0, res.msg.length - 1))
      })
    },
    // 获取轮播图详情
    getDetail() {
      const activeIndex = this.$refs.carousel.activeIndex
      this.detailData = this.picList[activeIndex].content
      this.carouselTitle = this.picList[activeIndex].title
      this.carouselTime = this.picList[activeIndex].createdTime
      this.carouselUserBy = this.picList[activeIndex].createBy
      this.loopShow = true
    }
  }
}
</script>

<style scoped lang="less">
//<!--滚动条消失-->
/*/deep/  .vue-waterfall-easy-scroll::-webkit-scrollbar {
  width: 0 !important
}*/
    .index{
        //margin-top: 80px;
        //background-image: url("../../assets/images/topBg.png") ;
        background-repeat:no-repeat;
        background-position:top;
      .user-avatar{
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
      .slideShow{
        //padding-top: 60px;
        //max-width:1200px;
        /deep/.el-carousel__arrow--right {
          margin-left: 327px;
          right: auto;
        }
        /deep/.el-carousel__arrow--left {
          left: auto;
          margin-left: -564px;
        }
        .Img{
          height: 360px;
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: 100% 100%;
          cursor: pointer;
        }

        .slide{
          width: 1200px;
          margin: auto;
          position: relative;
          //float: left;
          height: 335px;
          margin-top: -335px;
          .right{
            position: absolute;
            bottom: 54px;
            right: 0%;
            z-index: 2;
            >.box-card{
              width: 197px;
              height: 228px;
              background: #FFFFFF;
              box-shadow: 0px 4px 10px 0px rgba(1, 21, 55, 0.2);
              border-radius: 8px;
              ::v-deep .el-card__body{
                padding: 10px;
                display: none;
              }
              ::v-deep .el-card__header{
                padding: 26px 16px;
              }
              .clearFix{
                //padding: 0 25px;
                .avatar-wrapper {
                  float: left;
                  margin-right: 10px;

                }

                .img{
                  width: 62px;
                  height: 62px;
                  margin: 29px auto;
                  border-radius: 50%;
                  img{
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                  }
                }
                .name{
                  //padding-bottom: 20px;
                  //color: #4392FD;
                  //font-weight: 800;

                  float: left;
                  //margin-top: 27px;
                  p {
                    text-align: left;
                    font-size: 14px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #4392FD;
                  }
                }
                .message{
                  text-align: center;
                  font-size: 14px;
                  font-family: MicrosoftYaHei;
                  color: #333333;
                  margin-top: 20px;
                }
                .percent{
                  //width: 170px;
                  margin-top: 10px;

                  .msg{
                    margin-top: 15px;
                    font-size: 14px;
                    font-family: MicrosoftYaHei;
                    color: #333333;
                    padding-bottom: 44px;
                    span {
                      font-size: 22px;
                      color: rgba(67, 146, 253, 1);
                    }
                  }
                  ::v-deep.el-progress {
                    margin-top: 10px;
                    .el-progress-bar__outer {
                      background: #B0CEF6;
                      //这里是背景颜色
                    }
                  }
                }
              }
              .text{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                .box{
                  width: 195px;
                  height: 90px;
                  background: #EBF6FD;
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #39A5EF;
                  display: table-cell;
                  vertical-align:middle;
                  text-align: center;
                }
              }
            }
          }
        }

      }
        >.middle{
          //position: relative;
            width: 1200px;
            margin:0 auto;

        //    .slideShow{
        //        //width: 80%;
        //        //height: 400px;
        //        //display: flex;
        //        //padding-top: 20px;
        //        ////.left{
        //        //    width: 960px;
        //        //    flex-shrink: 0;
        //        //    margin-right: 18px;
        //        //}
        //
        //    }
        }
        ::v-deep.el-dialog{
            /*height: auto !important;*/
            max-height: 1000px;
            overflow: hidden;
            overflow: scroll;
            overflow: auto;
            background-size: contain!important;
            background-color: white;
            .el-dialog__body{
                img {
                    max-width: 100% !important;
                }
                .content{
                    min-height: 350px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
    /*这是更改富文本的设定*/
    .changeWidth {
        width: 100% !important;
        opacity: 50% !important;
    }
    .create{
      display: flex;
      justify-content: space-around;
      p{
        font-size: 15px;
      }
    }
    .content{
      padding-top: 20px;
      p{
        margin: 15px;
      }
    }
.publicity{
  margin-top: 10px;
}

    .pic-box{
      position: relative;
      width: 100%;
      min-height: 600px;
      ::v-deep.waterfull-box{
        position: absolute;
        top:6px;
        bottom:0;
        width:100%;
        /*  /deep/.vue-waterfall-easy-scroll{
              display: none;
          }*/

      }
      /*      /deep/.vue-waterfall-easy-container .vue-waterfall-easy-scroll{
              display: none;
            }*/
      //&::-webkit-scrollbar {display: none;}
    }
</style>
