<template>
  <div class="elegantDemeanor">
    <div class="title">
      <p>学员风采</p>
    </div>
    <!--      瀑布流盒子-->
    <div class="pic-box">
      <!--         vue插件实现-->
      <!--<div class="waterfull-box">
        <vue-waterfall-easy :imgsArr="picList"
                            @scrollReachBottom="getData"
                            :imgWidth="290"
                            :maxCols="4"
                            :gap="20"
                            :reachBottomDistance=0
                            :loadingDotCount='0'
                            class="test">
          <div slot-scope="props"
               style="padding: 10px;color: #666666;overflow: hidden">
            <div class="item" effect="dark" :content="props.value.info" placement="bottom">
              <div>{{props.value.title}}</div>
            </div>
          </div>
          <div slot="waterfall-over">waterfall-over</div>
        </vue-waterfall-easy>

      </div>-->
      <!--         四列-->
      <div class="water">
        <div ref="piping0" class="piping" />
        <div ref="piping1" class="piping" />
        <div ref="piping2" class="piping" />
        <div ref="piping3" class="piping" />
        <!--          遮罩-->
        <div v-if="cover" class="cover" />
      </div>
    </div>
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="urlList" />
  </div>
</template>

<script>
// import vueWaterfallEasy from 'vue-waterfall-easy'
import { faceShowList } from '@/api/welcome'

export default {
  name: 'ElegantDemeanor',
  components: {
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  props: {
    picList: Array
  },
  data() {
    return {
      showViewer: false,
      urlList: [],
      cover: false,
      /* currentIndex:null,*/
      // currentNum:10,
      PicList: [],
      initStudentObj: {
        pageSize: 20,
        pageNum: 1
      },
      // 获取滚轮位置
      scrollTop: 0,
      // 文档高度
      heightDocument: 0,
      // 可视区域
      viewHeight: 0,
      available: 1,
      a: 'display:none;position:absolute;left:0;top:0;width: 290px;height: 100%;background-color: rgba(0,0,0,.5);',
      b: 'display:block;position:absolute;left:0;top:0;width: 290px;height: 100%;background-color: rgba(0,0,0,.3);'
    }
  },
  created() {
    window.viewImage = this.viewImage
    this.onLoad()
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  methods: {

    onLoad() {
      this.getData()
    },
    getData() {
      // 获取学员风采
      faceShowList(this.initStudentObj).then(res => {
        if (res.code === 200) {
          this.PicList = res.rows
          this.sort(0, this.PicList)
        }
      })
    },
    viewImage(url) {
      this.urlList = [url]
      this.showViewer = true
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', (e) => e.preventDefault(), false)
    },
    closeViewer() {
      this.urlList = []
      this.showViewer = false
      document.body.style.overflow = 'auto'
      document.removeEventListener('touchmove', (e) => e.preventDefault(), true)
    },
    sort(j, PicList) {
      if (j < PicList.length) {
        const that = this
        // 创建Image类
        const newImg = new Image()
        // 获取要加载的图片地址
        newImg.src = this.$downloadUrl + PicList[j].fileUrls
        // console.log(newImg.src)
        // 图片加载完成后（异步）
        newImg.onload = () => {
          // 四个管道的高度
          const arr = [
            that.$refs.piping0.offsetHeight,
            that.$refs.piping1.offsetHeight,
            that.$refs.piping2.offsetHeight,
            that.$refs.piping3.offsetHeight
          ]
          // 获取管道最小高度
          const min = arr.indexOf(Math.min.apply(Math, arr))
          // 添加卡片的模板
          const html =
              `<div class="card" style="      position: relative;
            width: 290px;
            margin-bottom: 10px;
            overflow: hidden;">
                <img onclick="viewImage('${newImg.src}')" style="width:100%;border-radius: 10px;cursor: pointer" src=` + newImg.src + `>
                  <div class="title" style="
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;">` + PicList[j].title + `</div>
            </div>`
          // 给最小的管道添加卡片
          if (min === 0) {
            that.$refs.piping0.innerHTML += html
          } else if (min === 1) {
            that.$refs.piping1.innerHTML += html
          } else if (min === 2) {
            that.$refs.piping2.innerHTML += html
          } else if (min === 3) {
            that.$refs.piping3.innerHTML += html
          }
          that.sort(j + 1, PicList)
        }
      }
    },
    onScroll() {
      // 获取滚轮位置
      this.scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
      // 文档高度
      this.heightDocument = document.body.scrollHeight
      // 可视区域
      this.viewHeight =
          document.compatMode == 'CSS1Compat'
            ? document.documentElement.clientHeight
            : document.body.clientHeight
      // console.log(this.height3)

      // 如果滚动到最低（这里设置离最底还有100距离才触发函数）
      // available条件是为了防止触底时一直不断地请求。因此，请求一次后available设为0，直到滚动到离底部超过100距离（即数据加载完后）才设为1
      if (this.viewHeight + this.scrollTop >= this.heightDocument - 100 && this.available) {
        // 请求下一页
        this.initStudentObj.pageNum++
        this.available = 0
        faceShowList(this.initStudentObj).then(res => {
          if (res.code === 200) {
            // console.log(res.rows)
            // 总数据有限 判断是否还需要继续请求
            if (this.initStudentObj.pageNum <= res.total / this.initStudentObj.pageSize + 1) {
              this.sort(0, res.rows)
            } else {
              this.cover = true
            }
          }
        })
      } else if (this.viewHeight + this.scrollTop < this.heightDocument - 100) {
        this.available = 1
      }
    },
    mouseOn() {
      console.log(1111)
    }
  }
}
</script>

<style scoped lang="less">
.elegantDemeanor {
  margin: auto;
  width: 100%;

  .title {
    margin: 30px 0 10px 0;

    p {
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      text-align: left;
    }

  }

  /*  .picBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      div {
        //padding: 10px;
        margin-bottom: 20px;
        width: 390px;
        height: 240px;
        color: rgba(255, 255, 255, 1);
        line-height: 240px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }*/

  .pic-box {
    //position: relative;
    width: 100%;
    min-height: 600px;

    .water {
      width: 1240px;
      //margin-top: 60px;
      display: flex;
      align-items: flex-start;
      position: relative;

      .piping {
        width: 25%;
        padding: 10px;
        padding-bottom: 0px;
      }

      .cover {
        z-index: 99;
        background-color: rgba(255, 255, 255, 0.4);
        //background: linear-gradient(to top,rgba(0,0,0,.5),rgba(255,255,255,.1));
        position: absolute;
        width: 98.5%;
        height: 100px;
        bottom: 0;
        left: 10px;
        box-shadow: 0px -67px 100px 20px rgba(255, 255, 255, .5);
      }
    }

    .card {
      position: relative;
      width: 290px;
      border-radius: 30px;
      margin-bottom: 0px;
      overflow: hidden;

      img {
        width: 100% !important;
      }

      .title {
        max-width: 200px;
        color: white;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      /*     .mouseOn {
             display: none;
             position: absolute;
             left: 0;
             top: 0;
             width: 290px;
             height: 209px;
             background-color: rgba(0,0,0,.5);
             &:hover {
               display: block;
             }
           }*/
    }
  }

  /* ::v-deep.waterfull-box{
       position: absolute;
       top:6px;
       bottom:0;
       width:100%;
     !*  /deep/.vue-waterfall-easy-scroll{
           display: none;
       }*!

   }*/
  /*      /deep/.vue-waterfall-easy-container .vue-waterfall-easy-scroll{
          display: none;
        }*/
  //&::-webkit-scrollbar {display: none;}

  .selectShow {
    div {
      background-color: rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }
  }

  .noShow {
    color: #FFFFFF;
  }

  /* 定义滚动条样式 */

  .vue-waterfall-easy-scroll::-webkit-scrollbar {
    width: 20px;
    height: 6px;
    background-color: rgba(240, 240, 240, 1);
  }

  /*定义滚动条轨道 内阴影+圆角*/

  .vue-waterfall-easy-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
    border-radius: 10px;
    background-color: rgba(240, 240, 240, .5);
  }

  /*定义滑块 内阴影+圆角*/

  .vue-waterfall-easy-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
    background-color: rgba(240, 240, 240, .5);
  }

}
</style>
